import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Client from "shopify-buy"

import { Layout } from "../../../components/Layout"
import { SEO } from "../../../components/SEO"

import "../product.scss"
import "./no-shows.scss"

import productImg from "../../../images/sock-on-feet.jpg"
// import specterCpImg from "../../../images/specter-cp.jpg"
import meshImg from "../../../images/mesh.jpg"
import bandImg from "../../../images/band.jpg"
import gripImg from "../../../images/grip.jpg"

export const query = graphql`
  {
    shopifyProduct(handle: { eq: "no-shows" }) {
      id
      shopifyId
      title
      variants {
        availableForSale
        id
        shopifyId
        sku
        title
        price
      }
      productType
    }
  }
`

// TODO: move all this checkout stuff into store - maybe use easy peasy
const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
})

const VariantOption = ({ onClick, selected, variant }) => {
  const { title } = variant
  return (
    <button
      className={`btn btn--variant ${selected && "selected"}`}
      onClick={onClick}
    >
      {title}
    </button>
  )
}

const NoShowsPage = ({ data }) => {
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // set default selected variant to 3
    const [variant] = data.shopifyProduct.variants.filter(
      variant => variant.sku === "NS01-3"
    )
    setSelectedVariant(variant)
  }, [data])

  const variantOptions = data.shopifyProduct.variants
    .filter(variant => variant.availableForSale) // filter out variants not available for sale
    .map(variant => {
      // render variant options
      return (
        <li className="variant-item" key={variant.id}>
          <VariantOption
            variant={variant}
            onClick={() => handleVariantClick(variant)}
            selected={selectedVariant && variant.id === selectedVariant.id}
          />
        </li>
      )
    })

  const handleVariantClick = variant => {
    setSelectedVariant(variant)
  }

  const handleBuyClick = async () => {
    if (loading) {
      // already another checkout in progress
      return
    }

    if (!selectedVariant) {
      // no variant selected
      setError("Please select a quantity.")
      return
    }

    setLoading(true)
    setError(null)

    // init checkout
    const checkout = await client.checkout.create()
    const { id: checkoutId } = checkout

    // add line items
    const lineItems = [{ variantId: selectedVariant.shopifyId, quantity: 1 }]
    await client.checkout.addLineItems(checkoutId, lineItems)

    // visit checkout url
    window.open(checkout.webUrl, "_self")
    setLoading(false)
  }

  return (
    <Layout>
      <SEO
        description="Our classic no-show socks. Perfect for every occasion, these
              no-shows are designed to stay hidden and slip-free."
        title="No-Show Socks"
      />
      <section className="container product-container">
        <div className="product-gallery">
          <img src={productImg} alt="Specter No-Show Socks" />
        </div>
        <div className="product-info">
          <div className="product-description">
            <h1>No-Show Socks</h1>
            <p className="price">
              {selectedVariant && `$${selectedVariant.price}`}
            </p>
            <p>
              Our classic no-show socks. Perfect for every occasion, these
              no-shows are designed to stay hidden and slip-free.
            </p>
          </div>
          <div className="variants">
            <p>Select Quantity:</p>
            <ul className="variant-list">{variantOptions}</ul>
          </div>
          <div className="sizing">
            <p>
              Our socks feature an elastic fabric that stretches to fit almost
              any size (US 7-12).
            </p>
          </div>
          <div className="add-to-cart">
            <button className="btn" onClick={handleBuyClick} disabled={loading}>
              Buy Now
            </button>
            {error && <p className="error">{error}</p>}
          </div>
          <div className="shipping">
            <p>Free US shipping on orders over $35</p>
          </div>
        </div>
      </section>
      <section className="container">
        {/* <div className="image-header">
          <img src={specterCpImg} alt="Specter socks in white sneakers" />
        </div> */}
        <div className="features">
          <h2 className="features__title">
            Discover what makes our socks great
          </h2>
          <div className="container-grid container--center">
            <div className="container-grid__col feature">
              <img
                className="feature__image"
                src={meshImg}
                alt="Mesh on the mid-foot"
              />
              <h3 className="feature__title">Breathable & Durable</h3>
              <p className="feature__description">
                Mesh on the mid-foot increases breathability, while reinforced
                fabric on the toe and heel areas prevent tears.
              </p>
            </div>
            <div className="container-grid__col feature">
              <img
                className="feature__image"
                src={bandImg}
                alt="Sock opening band"
              />
              <h3 className="feature__title">Truly Invisible</h3>
              <p className="feature__description">
                The improved sock band wraps securely around your foot,
                maintaining a low profile while staying hidden in any shoe.
              </p>
            </div>
            <div className="container-grid__col feature">
              <img
                className="feature__image"
                src={gripImg}
                alt="Close-up of no-slip heel grip"
              />
              <h3 className="feature__title">No Slip</h3>
              <p className="feature__description">
                Our socks feature a premium no-slip heel grip engineered to
                ensure your socks stay put at all times.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NoShowsPage
